// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"src/routes/_index.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "src/routes/_index.tsx");
  import.meta.hot.lastModified = "1740495432000";
}
// REMIX HMR END

import { useLoaderData } from '@remix-run/react';
import { PlayIcon } from '@heroicons/react/24/outline';
import { getCollections } from '~storefront/providers/collections/provider';
import { activeChannel } from '~storefront/providers/channel/provider';
import { useRootLoader } from '~storefront/utils/useRootLoader';
import { HowToGetStarted } from '~storefront/components/LoggedInPageSections/HowToGetStarted';
import { ChooseCategorySection } from '~storefront/components/LoggedInPageSections/ChooseCategorySection';
// import { NewsLetterSection } from '~storefront/components/LoggedInPageSections/NewsLetterSection'
import { list as getLocations } from '~storefront/providers/location/provider';
import { Button } from '~ui/Button';
import { useScrollToSection } from '~common/hooks/useScrollToSection';
import { LocationSection } from '~storefront/components/LoggedInPageSections/LocationSection';
import clsx from 'clsx';
export async function loader({
  request
}) {
  const {
    collections: collectionsData
  } = await getCollections(undefined, {
    request
  });
  const channel = await activeChannel({
    request
  });
  const locations = await getLocations(undefined, {
    request
  });
  return {
    collections: collectionsData.items,
    channel,
    locations: locations.rsv_locations.items
  };
}
export default function Index() {
  _s();
  const {
    collections,
    channel,
    locations
  } = useLoaderData();
  const data = useRootLoader();
  const scollToCategory = useScrollToSection('#choosecategory-section');
  const isSignedIn = !!data.activeCustomer.activeCustomer?.id;
  const headerImage = channel?.customFields?.storeHero?.preview ?? collections[0]?.featuredAsset?.preview;
  let storeImage = undefined;
  let storeHero = headerImage;
  // let storeHeroOpacity = 50;
  let storeTitle = 'Your New Store';
  let storeSlogan = 'You Store Slogan';
  if (channel.customFields) {
    storeHero = channel.customFields.storeHero?.preview ? channel.customFields.storeHero.preview : headerImage;
    // storeHeroOpacity = channel.customFields.storeHeroOpacity ? Number(channel.customFields.storeHeroOpacity) : storeHeroOpacity;
    storeImage = channel.customFields.storeLogo?.preview ? channel.customFields.storeLogo.preview : storeImage;
    storeTitle = channel.customFields.storeTitle ? channel.customFields.storeTitle : '';
    storeSlogan = channel.customFields.storeSlogan ? channel.customFields.storeSlogan : '';
  }
  return <div>
      {!isSignedIn && <div className="relative">
          {/* Decorative image and overlay */}
          <div aria-hidden="true" className="absolute inset-0 overflow-hidden">
            {storeHero && <img className="absolute inset-0 h-full lg:w-full object-cover" src={storeHero + '?w=800'} alt="header" />}
            <div className="absolute inset-0" />
          </div>
          <div aria-hidden="true" className="absolute inset-0 bg-gray-900 opacity-25" />
          <div className={clsx('relative py-24 flex flex-col justify-start items-start text-left space-y-5 ml-0 sm:mx-auto px-4 lg:px-8 lg:max-w-lg lg:py-72 xl:max-w-7xl')}>
            <p className="text-white font-bold">
              {storeTitle?.length ? storeTitle : 'TrueNorth Martial Arts'}
            </p>
            {storeSlogan?.length ? <div className="text-5xl font-bold text-white leading-tight lg:text-6xl" dangerouslySetInnerHTML={{
          __html: storeSlogan
        }} /> : <h2 className="text-5xl font-bold text-white leading-tight lg:text-6xl">
                Have fun, make friends and learn martial arts
              </h2>}
            <Button onClick={scollToCategory} variant="unstyled" className="rounded-full bg-red-600 w-36 text-white px-4 py-3 flex justify-around items-center hover:bg-red-700 focus:ring-2 focus:ring-offset-1 focus:ring-red-700">
              <PlayIcon color="white" className="w-4 h-4" />
              Let's start
            </Button>
          </div>
        </div>}

      <LocationSection locations={locations} />
      <ChooseCategorySection collections={collections} />
      <HowToGetStarted />
      {/* <TestimonialSection /> */}
    </div>;
}
_s(Index, "EijPotoxL7XTuVJBmh0ejio3K2w=", false, function () {
  return [useLoaderData, useRootLoader, useScrollToSection];
});
_c = Index;
var _c;
$RefreshReg$(_c, "Index");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;