// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"src/components/LoggedInPageSections/HowToGetStarted.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "src/components/LoggedInPageSections/HowToGetStarted.tsx");
  import.meta.hot.lastModified = "1740495432000";
}
// REMIX HMR END

import { CheckBadgeIcon, HeartIcon, SparklesIcon, UserPlusIcon } from '@heroicons/react/24/outline';
export const steps = [{
  icon: <CheckBadgeIcon color="#E41E26" />,
  title: 'Register',
  text: 'You just need to be +18 years old to create a registration'
}, {
  icon: <UserPlusIcon color="#E41E26" />,
  title: 'Create your family/dependents',
  text: 'If you have children or dependents who wish to take classes, link them to your registration.'
}, {
  icon: <SparklesIcon color="#E41E26" />,
  title: 'Choose a plan',
  text: 'You can choose a plan for yourself or your relative.'
}, {
  icon: <HeartIcon color="#E41E26" />,
  title: "That's it.",
  text: 'We are waiting for you in class, have fun!'
}];
export function HowToGetStarted({}) {
  return <section aria-labelledby="how-to-get-started" className="pt-24 px-4 sm:pt-32 xl:max-w-7xl xl:mx-auto xl:px-8">
      <div className="mt-4 flow-root -my-2 flex flex-col justify-center items-center sm:px-4">
        <p className="dark:text-primary-400 text-dark-blue font-bold text-center">
          Let's start?
        </p>
        <h2 className="text-4xl font-bold tracking-tight text-gray-900 dark:text-slate-300 text-center">
          Starting to have fun is very simple. It's just a few steps.
        </h2>
      </div>
      <div className="flex flex-col justify-start items-center space-y-8 mt-16 lg:flex-row lg:justify-around lg:items-start lg:space-y-0">
        {steps.map((step, index) => <div className="flex flex-col justify-start items-center w-full px-4 sm:px-8 space-y-3 lg:w-[230px] " key={step.title}>
            <div className="bg-red-50 rounded-lg w-14 h-14 p-2">
              {step.icon}
            </div>
            <h2 className="text-xl dark:text-slate-300 font-bold color-black text-center">
              {step.title}
            </h2>
            <h3 className="text-lg color-black font-normal text-center">
              {step.text}
            </h3>
          </div>)}
      </div>
    </section>;
}
_c = HowToGetStarted;
var _c;
$RefreshReg$(_c, "HowToGetStarted");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;